<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          @inputChange="handleChange"
          @dialogChange="handleDialog"
        >
          <template #[`item.name`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ item.name }}
              </div>
              <div class="grey--text small">
                {{ item.description || '' }}
              </div>
            </div>
          </template>
          <template #[`item.is_secret`]="{ item }">
            <v-switch
              :input-value="item.is_secret"
              hide-details="auto"
              disabled
            />
          </template>

          <template #top-action-buttons>
            <v-dialog
              v-model="resetSettingsDialog"
              v-can="'settings.restore'"
              persistent
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-can="'affiliates.import'"
                  color="warning mr-3"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('settings.reset') }}
                  <v-icon
                    right
                    dark
                  >
                    mdi-cog-refresh-outline
                  </v-icon>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t('settings.dialog.restoreTitle') }}
                </v-card-title>
                <v-card-text>{{ $t('settings.dialog.restoreWarningText') }}</v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="green darken-1"
                    text
                    @click="resetSettingsDialog = false"
                  >
                    {{ $t('settings.dialog.disagree') }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    :loading="resetLoading"
                    @click="resetSettings()"
                  >
                    {{ $t('settings.dialog.agree') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Settings',
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('settings.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'settings',
    editedItem: {},
    resetSettingsDialog: false,
    resetLoading: false
  }),
  async mounted () {
    this.title = this.$t('settings.title')
    this.tableHeaders = [
      {
        text: this.$t('settings.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('settings.table.value'),
        value: 'value'
      },
      {
        text: this.$t('settings.table.isSecret'),
        value: 'is_secret',
        width: '90px',
        align: 'center'
      }
    ]
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('settings.tableForm.name'),
        createRules: 'req',
        updateRules: [],
        md: 12
      },
      value: {
        type: 'textarea',
        label: this.$t('settings.tableForm.value'),
        createRules: 'req',
        updateRules: [],
        'auto-grow': true,
        rows: 1,
        md: 12
      },
      description: {
        type: 'textarea',
        label: this.$t('settings.tableForm.description'),
        createRules: [],
        updateRules: [],
        'auto-grow': true,
        rows: 1,
        md: 12
      },
      is_secret: {
        type: 'switch',
        label: this.$t('settings.tableForm.isSecret'),
        createRules: [],
        updateRules: []
      }
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async resetSettings() {
      try {
        this.resetLoading = true
        await this.$store.dispatch('settings/restoreSettings')
      } catch (e) {
        console.log(e)
      } finally {
        this.resetLoading = false
        this.resetSettingsDialog = false
      }
    },
    handleChange(event) {
      if (event.name) {
        event.name = this.$helper.convertToSlug(event.name, '_')
      }
      this.editedItem = event
    },
    handleDialog(params) {
      if (params.dialog === true) {
        if (params.item.is_secret === true) {
          params.item.value = undefined
        }
        this.editedItem = params.item
      }
    }
  }
}
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 0px;
}
</style>
