var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource},on:{"inputChange":_vm.handleChange,"dialogChange":_vm.handleDialog},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.description || '')+" ")])])]}},{key:"item.is_secret",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.is_secret,"hide-details":"auto","disabled":""}})]}},{key:"top-action-buttons",fn:function(){return [_c('v-dialog',{directives:[{name:"can",rawName:"v-can",value:('settings.restore'),expression:"'settings.restore'"}],attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"can",rawName:"v-can",value:('affiliates.import'),expression:"'affiliates.import'"}],attrs:{"color":"warning mr-3","small":""},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('settings.reset'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cog-refresh-outline ")])],1)]}}]),model:{value:(_vm.resetSettingsDialog),callback:function ($$v) {_vm.resetSettingsDialog=$$v},expression:"resetSettingsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('settings.dialog.restoreTitle'))+" ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('settings.dialog.restoreWarningText')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.resetSettingsDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('settings.dialog.disagree'))+" ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.resetLoading},on:{"click":function($event){return _vm.resetSettings()}}},[_vm._v(" "+_vm._s(_vm.$t('settings.dialog.agree'))+" ")])],1)],1)],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }